.root {
  background-color: #dd5a35;
  color: #333;
  padding: 2px 4px 2px 6px;
  border-radius: 4px;
  font-size: 10px;
  vertical-align: top;
  margin: 0 4px;
  font-weight: 600;
  letter-spacing: 2px;
}
