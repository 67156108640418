@import url("../../config.css");

.root {
  position: fixed;
  display: flex;
  flex-direction: column;
  height: auto;
  max-width: 400px;
  overflow-y: scroll;
  padding: 32px 32px 0 0;
  right: 0;
  top: 100px;
  width: 25%;
  z-index: var(--zIndexNotification);
}

.message {
  display: block;
  flex-grow: 1;
  font-size: 16px;
  padding: 12px;
}

.close {
  border: 0;
  background: transparent;
  color: var(--white);
  cursor: pointer;
  font-size: 16px;
  font-weight: var(--fontWeightBold);
  padding: 8px;
}

.notification {
  animation: fade 5s linear;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  transition: var(--transitionNatural);
}

.info {
  background: var(--infoColor);
  color: var(--white);
}

.error {
  background: var(--failColor);
  color: var(--white);
}

.success {
  background: var(--successColor);
  color: var(--white);
}

.warning {
  background: var(--warnColor);
  color: var(--white);
}

@keyframes fade {
  0%,
  100% {
    opacity: 0;
  }
  5%,
  95% {
    opacity: 1;
  }
}
