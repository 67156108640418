.root {
  border-radius: 4px;
  color: white;
  display: block;
  font-size: 14px;
  line-height: 100%;
  padding: 4px 8px;
  text-align: center;
  text-transform: uppercase;
}
.completed {
  background: green;
  opacity: 0.6;
}
.delivered {
  background: green;
  opacity: 0.4;
}
.closed {
  background: green;
}
.unknown,
.cancelled {
  background: red;
}
.expired {
  background: dimgrey;
}
.declined {
  background: darkgrey;
}
.accepted {
  background: dodgerblue;
}
.initiated {
  background: orange;
}
.failed {
  background: #ff3300;
}
