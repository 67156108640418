@import url("../../config.css");

.root {
}

.items {
  margin: 0;
  padding: 0;
}

.item {
  list-style-type: none;
  text-align: left;
}

.itemLink {
  border-right: 4px solid transparent;
  color: var(--textDark);
  display: block;
  font-size: 24px;
  font-weight: var(--fontWeightMedium);
  padding: 16px;
  text-decoration: none;

  &:hover {
    border-color: var(--marketplaceColor);
  }
}
