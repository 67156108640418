@import url("../../config.css");

.root {
}

.submit {
  margin-top: 80px;
}

.readOnlyField p {
  display: block;
  margin-top: 0;
}

.status.notApproved,
.status.approved {
  border-radius: 50%;
  display: inline-block;
  height: 12px;
  width: 12px;
}

.status.notApproved {
  background: var(--warnColor);
}

.status.approved {
  background: var(--successColor);
}
