@import url("../../config.css");

.root {
  padding-bottom: 32px;
}
.tags {
  margin: 12px 0;
}

.addTag {
  display: flex;
  flex-direction: row;
  max-width: 50%;
}

.selectTag {
  -o-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.selectTag:disabled {
  cursor: not-allowed;
}

.addButton {
  background: transparent;
  border: 1px solid var(--matterColorAnti);
  border-radius: 4px;
  cursor: pointer;
  font-weight: var(--fontWeightBold);
  margin-left: 24px;
  min-width: 60px;
}

.addButton:disabled:hover {
  border: 1px solid var(--matterColorAnti);
  box-shadow: none;
  cursor: not-allowed;
}

.addButton:focus {
  outline: none;
}

.addButton:hover {
  border-color: var(--textDark);
  box-shadow: var(--boxShadowButton);
}
