@import url("../../config.css");

.root {
}

.dropzone {
  border: 2px dashed var(--matterColorNegative);
  text-align: center;
}

.dropzoneText {
  margin: 0;
  padding: 2rem;
}
.loading {
  display: flex;
  flex-direction: row;
  line-height: 32px;
  vertical-align: middle;
}
.loader {
  display: inline-block;
  height: 32px;
  margin-right: 16px;
  width: 32px;
}

.dataTable {
  font-size: 20px;
  flex-grow: 1;
  margin-right: 32px;
}
.dataHeader {
}
.dataHeaderColumn {
  background-color: var(--marketplaceColorOpacity15);
  border: 1px solid var(--matterColorNegative);
  font-weight: var(--fontWeightMedium);
  line-height: 32px;
  padding: 0 8px;
  text-align: left;
}
.dataKeyColumn,
.dataColumn {
  border: 1px solid var(--matterColorNegative);
  line-height: 32px;
  padding: 0 8px;
  vertical-align: top;
}
.dataKeyColumn {
  background-color: var(--matterColorBright);
  font-weight: var(--fontWeightBold);
}

.fieldWrapper {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  padding-bottom: 32px;
}
.details {
  display: flex;
  flex-direction: row;
  max-width: 720px;
  padding-bottom: 64px;
}
.statistics {
  flex-grow: 1;
  margin-left: 32px;
}
.controls {
}
.success,
.warn {
  fill: green;
  height: 20px;
  width: 20px;
}
