.root {
  background: var(--matterColorLight);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 300px;
  position: fixed;
  text-align: center;
  width: 100%;
}

.logo {
  padding: 2rem;
}
