:root {
  --textDark: #1c1a34;

  --marketplaceColor: #fac51d;
  --marketplaceColorLightest: #faf4d5;
  --marketplaceColorLight: #fac51d;
  --marketplaceColorOpacity15: #fac51d18;
  --marketplaceColorSlightlyDark: #f8be05;
  --marketplaceColorDark: #e5b700;
  --marketplaceColorFaded: #faf4d5;
  --white: #fff;

  --successColor: #2ecc71;
  --successColorLight: #edfff6;
  --successColorDark: #239954;
  --failColor: #ff0000;
  --warnColor: #ffaa00;
  --infoColor: #007bff;

  --matterColorDark: #000000;
  --matterColor: var(--textDark);
  --matterColorAnti: #b2b2b2;
  --matterColorNeutral: #fafafa;
  --matterColorNegative: #e6e6e6;
  --matterColorBright: #fcfcfc;
  --matterColorLight: #ffffff;

  --fontWeightRegular: 400;
  --fontWeightMedium: 500;
  --fontWeightBold: 700;

  --boxShadowButton: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  --boxShadowContextMenu: 0 4px 8px 0 rgba(0, 0, 0, 0.3);

  --desktopModalWidth: 600px;
  --desktopMainContentMaxWidth: 800px;
  --desktopFormMaxWidth: 600px;

  --transitionNatural: 0.4s;

  --zIndexContextMenu: 40;
  --zIndexNotification: 50;

  --iconClear: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%0A%20%20%20%20%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20stroke%3D%22%231C1A34%22%20stroke-linecap%3D%22round%22%20stroke-width%3D%221.5%22%3E%0A%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M0%200L14.142%2014.142%22%20transform%3D%22translate%281%201%29%22%2F%3E%0A%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M0%200L14.142%2014.142%22%20transform%3D%22translate%281%201%29%20matrix%28-1%200%200%201%2014.142%200%29%22%2F%3E%0A%20%20%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A")
    center center no-repeat;
  --memberAvatarPlaceholder: url("data:image/svg+xml;utf8,%3Csvg%20className%3D%7BclassName%7D%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20240%20240%22%3E%0A%20%20%20%20%20%20%3Cg%20fill%3D%22none%22%20fillRule%3D%22evenodd%22%3E%0A%20%20%20%20%20%20%20%20%3Ccircle%20className%3D%7Bcss.background%7D%20cx%3D%22120%22%20cy%3D%22120%22%20r%3D%22120%22%20%2F%3E%0A%20%20%20%20%20%20%20%20%3Cg%20className%3D%7Bcss.foreground%7D%3E%0A%20%20%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M119.8%20121c-13.254%200-24-10.746-24-24s10.746-24%2024-24c13.256%200%2024%2010.746%2024%2024s-10.744%2024-24%2024M160.6%20168.2h-16c0-13.674-11.126-24.8-24.8-24.8-13.674%200-24.8%2011.126-24.8%2024.8H79c0-22.497%2018.302-40.8%2040.8-40.8%2022.498%200%2040.8%2018.303%2040.8%2040.8%22%20%2F%3E%0A%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%3C%2Fsvg%3E")
    center center no-repeat;
  --nonprofitAvatarPlaceholder: url("data:image/svg+xml;utf8,%3Csvg%0A%20%20%20%20%20%20className%3D%7BclassName%7D%0A%20%20%20%20%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%0A%20%20%20%20%20%20width%3D%22240%22%0A%20%20%20%20%20%20height%3D%22240%22%0A%20%20%20%20%20%20viewBox%3D%220%200%20240%20240%22%0A%20%20%20%20%3E%0A%20%20%20%20%20%20%3Cg%20fill%3D%22none%22%20fillRule%3D%22evenodd%22%3E%0A%20%20%20%20%20%20%20%20%3Ccircle%20className%3D%7Bcss.background%7D%20cx%3D%22120%22%20cy%3D%22120%22%20r%3D%22120%22%20%2F%3E%0A%20%20%20%20%20%20%20%20%3Cpath%0A%20%20%20%20%20%20%20%20%20%20className%3D%7Bcss.foreground%7D%0A%20%20%20%20%20%20%20%20%20%20d%3D%22M159.597%20131.393l11.34%2011.31L119.5%20194l-51.437-51.297%2011.34-11.31L119.5%20171.38l40.097-39.987zm-56.55-28.366c9.395-9.37%2024.628-9.37%2034.023%200%209.395%209.37%209.395%2024.561%200%2033.931-9.396%209.369-24.628%209.37-34.023%200-9.396-9.37-9.396-24.56%200-33.93zm18.154-26.1c15.946-15.903%2041.893-15.903%2057.84%200%2015.946%2015.903%2015.946%2041.779%200%2057.682l-11.342-11.31c9.692-9.667%209.692-25.395%200-35.062-9.693-9.666-25.464-9.666-35.157%200zm-61.242%200c15.947-15.903%2041.893-15.903%2057.84%200l-11.341%2011.31c-9.693-9.666-25.465-9.666-35.158%200-9.693%209.667-9.692%2025.395%200%2035.062l-11.34%2011.31c-15.947-15.903-15.946-41.78%200-57.682z%22%0A%20%20%20%20%20%20%20%20%2F%3E%0A%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%3C%2Fsvg%3E")
    center center no-repeat;

  @media (prefers-color-scheme: dark) {
    --matterColorBright: #1e1e1e;
    --matterColorLight: #2a2a2a;
    --matterColorNeutral: #1e1e1e;
    --matterColorNegative: #2a2a2a;
    --matterColorDark: #dadada;
    --textDark: #adadad;
  }
}
