@import url("../../config.css");

.root {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.page {
  border-radius: 50%;
  color: var(--textDark);
  display: inline-block;
  font-weight: var(--fontWeightBold);
  height: 30px;
  line-height: 30px;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  width: 30px;
}

.page:hover {
  background: var(--marketplaceColor);
  color: var(--matterColorDark);
}

.currentPage {
  background-color: var(--marketplaceColorFaded);
  text-decoration: underline;
}

.root li {
  list-style-type: none;
}
.root li a {
  color: var(--textDark);
}
.first a {
  margin-right: 1em;
}
.last a {
  margin-left: 1em;
}
.loading {
  content: " ";
}
