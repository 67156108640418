@import url("./config.css");

body {
  background: var(--matterColorBright);
  color: var(--textDark);
  font-family: GTWalsheimPro, sans-serif;
  min-height: 100vh;
}

h1 {
  font-size: 48px;
  font-weight: var(--fontWeightMedium);
  line-height: 56px;
}

h2 {
  color: var(--matterColorAnti);
  font-size: 20px;
  font-weight: var(--fontWeightBold);
  line-height: 24px;
}

p {
  font-size: 20px;
  font-weight: var(--fontWeightRegular);
  line-height: 32px;
  margin-bottom: 32px;
}

input:focus,
select:focus,
button:focus {
  outline: none;
}

ul {
  margin-bottom: 32px;
}

li {
  font-size: 20px;
  line-height: 24px;
}

li a {
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightMedium);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

table {
  border-collapse: collapse;
}

label {
  font-size: 16px;
  font-weight: var(--fontWeightMedium);
  line-height: 24px;
}

input,
textarea,
select {
  border: 0;
  border-bottom: 2px solid var(--textDark);
  font-family: GTWalsheimPro, sans-serif;
  font-size: 20px;
  font-weight: var(--fontWeightRegular);
  line-height: 24px;
  padding: 4px 0 10px 0;
  width: 100%;

  &:focus {
    outline: none;
  }
}

select {
  background: url(data:image/svg+xml;utf8,%3Csvg%20width%3D%2214%22%20height%3D%229%22%20viewBox%3D%220%200%2014%209%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M6.53%207.472c.26.26.68.26.94%200l5.335-5.333c.26-.263.26-.684%200-.944-.26-.26-.683-.26-.943%200L7%206.056l-4.862-4.86c-.26-.26-.683-.26-.943%200-.26.26-.26.68%200%20.943L6.53%207.47z%22%20stroke%3D%22%25234A4A4A%22%20fill%3D%22%25234A4A4A%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E)
    right center no-repeat;
}

dl {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 20px;
  line-height: 32px;
  margin: 0;
  padding: 0;
}

dt {
  width: 50%;
}

dd {
  font-weight: var(--fontWeightMedium);
  margin: 0;
  width: 50%;
}

table {
  border: 1px solid var(--matterColorNegative);
  border-collapse: collapse;
  margin: 0 0 80px 0;
  width: 100%;
}

thead tr th {
  background: var(--matterColorNegative);
  font-size: 14px;
  line-height: 32px;
  text-transform: uppercase;
}

tbody tr td {
  font-size: 16px;
  line-height: 32px;
  padding: 4px 12px;
}

tbody tr td a {
  color: var(--textDark);
}

tbody tr td a:hover {
  text-decoration: none;
}

.App {
  display: flex;
  flex-direction: row;
}

.content {
  margin-left: 300px;
  padding: 100px;
}
