.input {
  accent-color: var(--marketplaceColor);
  width: 16px;
  height: 16px;
}

.label {
  display: flex;
  user-select: none;
}
