@import url("../../config.css");

.root {
  background: var(--matterColorNegative);
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}

.form {
  background: var(--white);
  margin: 0 auto;
  max-width: var(--desktopModalWidth);
  padding: 40px;
  width: 100%;
}

.header {
  color: var(--textDark);
  font-size: 30px;
  font-weight: var(--fontWeightMedium);
  margin-bottom: 32px;
}
