@import url("../../config.css");

.statusHeader {
  width: 41px;
}

.photoHeader {
  width: 48px;
}

.draft,
.published,
.dummyStatus {
  border-radius: 50%;
  display: inline-block;
  height: 12px;
  width: 12px;
}

.draft {
  background: var(--warnColor);
}

.published {
  background: var(--successColor);
}

.profileImage,
.profileImagePlaceholder,
.dummyPhoto {
  border-radius: 50%;
  height: 40px;
  width: 40px;
}
.profileImagePlaceholder {
  background: var(--memberAvatarPlaceholder);
  background-size: contain;
  display: block;
}

.dummyStatus,
.dummyPhoto,
.dummyName,
.dummyCompany {
  display: inline-block;
  background: var(--matterColorNegative);
}

.dummyStatus::before,
.dummyPhoto::before,
.dummyName::before,
.dummyCompany::before {
  animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  background: linear-gradient(
    to bottom right,
    transparent 0%,
    var(--matterColorBright) 50%,
    transparent 100%
  );
  content: "";
  display: inline-block;
  height: 100%;
  left: -100%;
  position: absolute;
  top: 0;
  width: 40px;
}

.dummyName,
.dummyCompany {
  height: 18px;
  width: 200px;
}
.dummyCompany {
  width: 140px;
}

.loading td {
  position: relative;
  overflow: hidden;
}

@keyframes load {
  from {
    left: -100%;
    top: 0;
  }
  to {
    left: 100%;
    top: 0;
  }
}
