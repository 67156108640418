@import url("../../config.css");

table tbody tr:nth-child(2n) {
  @media (prefers-color-scheme: dark) {
    background: #2a2a2a;
  }
}

.row {
  display: flex;
  align-items: baseline;
  gap: 32px;
}

.row.grow > * {
  flex: 1;
}

.loader-background {
  --loader-color: #e6e6e6;
  animation: loader 1000ms cubic-bezier(0.4, 0, 0.2, 1) infinite;
  background-image: linear-gradient(
    45deg,
    transparent 0% 25%,
    var(--loader-color) 50% 75%,
    transparent 100%
  );
  background-size: 200%;
  background-position: 200%;

  @media (prefers-color-scheme: dark) {
    --loader-color: #676767;
  }
}

tr:nth-child(even) .loader-background {
  --loader-color: #dadada;

  @media (prefers-color-scheme: dark) {
    --loader-color: #2a2a2a;
  }
}

@keyframes loader {
  to {
    background-position: 0%;
  }
}

.check-box-field {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.check-box-field input {
  width: auto;
  margin-right: 8px;
  accent-color: var(--marketplaceColor);
}

.error {
  background-color: #f78989;
  padding: 16px;
  border: 1px solid #a30e0e;
  color: #620404;
  border-radius: 4px;
  margin-bottom: 16px;
  position: sticky;
  top: 16px;
  z-index: 1000;
  white-space: pre-line;
}

.checkbox {
  width: auto;
}

.fieldset {
  margin: 0 0 36px auto;
  padding: 32px;
}

.col {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.secondary-button {
  appearance: none;
  align-self: flex-end;
  min-width: 96px;
  min-height: 32px;
  padding: 8px 16px;
  background-color: var(--marketplaceColorLightest);
  font: inherit;
  cursor: pointer;
  border-radius: 2px;
  border: 2px solid var(--marketplaceColorDark);
}

.dropzone {
  background-color: #efefef;
  padding: 64px;
  border: 2px dashed #dadada;
  border-radius: 4px;

  @media (prefers-color-scheme: dark) {
    background-color: #2a2a2a;
    border: 2px dashed #676767;
  }
}

.error-table {
  background-color: #f94646;
  border: none;
}

.error-table caption {
  font-size: 16px;
  text-align: left;
  margin-bottom: 8px;
  text-transform: uppercase;
  font-weight: bold;
}

.error-table caption::before,
.error-table caption::after {
  content: "⚠️";
}

.error-table caption::before {
  margin-right: 4px;
}

.error-table caption::after {
  margin-left: 4px;
}

.error-table tr:nth-child(2n),
.error-table tr th {
  background-color: #c43a3a;
}

.error-table tr td,
.error-table tr th {
  padding: 16px;
  vertical-align: top;
}

.btn-small {
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 2px;
  position: relative;
  flex: none;
}

.btn-small::before {
  content: attr(data-tooltip);
  position: absolute;
  top: -8px;
  right: 0;
  transform: translateY(-100%) scale(0);
  background-color: #000;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  transition: transform 100ms ease, opacity 100ms ease;
}

.btn-small:hover::before {
  opacity: 1;
  transform: translateY(-100%) scale(1);
}

.checkbox-sub-group {
  margin-top: -8px;
  background-color: #efefef;
  padding: 8px 16px;
  border-radius: 4px;
  margin-bottom: 12px;

  @media (prefers-color-scheme: dark) {
    background-color: #2a2a2a;
  }
}

.checkbox-sub-group label {
  padding: 8px;
}

.tabnav {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 4px solid #dadada;

  @media (prefers-color-scheme: dark) {
    border-bottom: 4px solid #2a2a2a;
  }

  & > a {
    font-weight: 700;
    color: inherit;
    text-decoration: none;
    font-size: 18px;
    padding: 16px;
    box-sizing: border-box;
    position: relative;

    &:hover {
      background-color: #f6f6f6;

      @media (prefers-color-scheme: dark) {
        background-color: #2a2a2a;
      }
    }
  }
}

a.active-tab {
  &:after {
    content: "";
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: var(--marketplaceColor);
  }

  background-color: #f1f1f1;

  @media (prefers-color-scheme: dark) {
    background-color: #2a2a2a;
  }
}

input,
textarea {
  @media (prefers-color-scheme: dark) {
    background-color: #2a2a2a;
    color: #fff;
  }
}

textarea {
  resize: vertical;
}

button[type="submit"] {
  @media (prefers-color-scheme: dark) {
    color: #353535;
  }
}

select {
  @media (prefers-color-scheme: dark) {
    background-color: #2a2a2a;
    color: #fff;
  }
}

table {
  margin: 32px 0;
}

.stack {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
