@import url("../../config.css");

.root {
  background-color: var(--marketplaceColor);
  border: none;
  border-radius: 4px;
  color: var(--textDark);
  cursor: pointer;
  font-family: GTWalsheimPro, sans-serif;
  font-size: 18px;
  font-weight: var(--fontWeightBold);
  letter-spacing: -0.3px;
  padding: 20px 80px;
}

.root:hover {
  box-shadow: var(--boxShadowButton);
}

.root:disabled {
  background: var(--matterColorNegative);
  color: var(--white);
  cursor: not-allowed;
}
.root:disabled:hover {
  box-shadow: none;
}
