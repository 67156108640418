@import url("../../config.css");

.root {
  margin-bottom: 32px;
}

.activeFilters {
  display: block;
  margin-bottom: 24px;
}

.activeFilter {
  background: var(--marketplaceColorLightest) var(--iconClear);
  background-position: right 16px center;
  background-size: 10px 10px;
  border-radius: 12px;
  color: var(--marketplaceColorDark);
  display: inline-block;
  font-size: 12px;
  font-weight: var(--fontWeightBold);
  line-height: 24px;
  margin-right: 12px;
  padding: 0 40px 0 16px;
  text-decoration: none;
}
