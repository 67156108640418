@import url("../../config.css");

.root {
  border: 1px solid var(--matterColorNegative);
  border-collapse: collapse;
  margin: 0 0 80px 0;
}

.headerCel {
  background: var(--matterColorNegative);
  font-size: 14px;
  line-height: 32px;
  text-transform: uppercase;
}
.bodyCel {
  font-size: 16px;
  line-height: 32px;
  padding: 4px 12px;
}
.action {
}

.action:hover {
  box-shadow: var(--boxShadowButton);
}
