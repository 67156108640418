@import url("../../config.css");

.root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
  padding: 16px;
}
.userName {
  line-height: 32px;
}
.logout {
  background: var(--white);
  border: 1px solid var(--textDark);
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: var(--fontWeightBold);
  line-height: 32px;
}

.logout:hover {
  background: var(--matterColorNegative);
  box-shadow: var(--boxShadowButton);
}
