@import url("../../config.css");

.root {
  background-color: var(--white);
  border: 1px solid var(--matterColorAnti);
  border-radius: 4px;
  color: var(--textDark);
  cursor: pointer;
  font-family: GTWalsheimPro, sans-serif;
  font-size: 18px;
  font-weight: var(--fontWeightMedium);
  letter-spacing: -0.3px;
  line-height: 24px;
  padding: 0 12px;
  text-decoration: none;
}

.root:hover {
  box-shadow: var(--boxShadowButton);
}

.root:disabled {
  background: var(--matterColorNegative);
  color: var(--white);
  cursor: not-allowed;
}
.root:disabled:hover {
  box-shadow: none;
}
