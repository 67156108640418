.label {
  width: 40%;
  display: inline-block;
  box-sizing: border-box;
  text-align: left;
}

.value {
  text-align: left;
  padding-left: 10px;
  width: 60%;
  display: inline-block;
  box-sizing: border-box;
}

/**
 * Table
 */
table {
  margin-top: 24px;
}
table tr th {
  background-color: var(--matterColorNegative);
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 16px;
  cursor: pointer;
  font-size: 14px;
  padding: 8px 20px 8px 8px;
  line-height: 100%;
  text-transform: uppercase;
  text-align: left;
}

table tbody tr:nth-child(2n) {
  background: #efefef;
}
table tbody tr td {
  font-size: 16px;
  line-height: 120%;
}

.error {
  border: 1px solid rgba(255, 0, 0, 0.1);
  border-radius: 4px;
  background: rgba(255, 0, 0, 0.05);
  color: var(--failColor);
  padding: 24px;
}
.error code {
  display: block;
  font-size: 0.8em;
}
