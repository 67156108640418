@import url("../../config.css");

.root {
  background: var(--marketplaceColorLightest);
  border: 0;
  border-radius: 16px;
  color: var(--marketplaceColorDark);
  display: inline-block;
  font-size: 16px;
  font-weight: var(--fontWeightBold);
  line-height: 32px;
  margin-right: 16px;
  padding: 0 16px;
  text-decoration: none;
}

button.root {
  cursor: pointer;
}
