@import url("../../config.css");

.root {
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
}

.success {
}

.error {
}

.message {
  color: var(--failColor);
}
