@import url("../../config.css");

.root {
  padding-bottom: 32px;
}

.buttons {
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 12px 0;
}
.radioButton {
  padding-right: 12px;
  width: auto;
}
.radioLabel {
  padding-right: 24px;
}
