@import url("../../config.css");

.root {
  padding-bottom: 32px;
  position: relative;
}

.nonprofits {
  display: flex;
  flex-direction: column;
}

.nonprofit {
  align-items: center;
  background: var(--marketplaceColorFaded);
  border: none;
  border-radius: 4px;
  color: var(--textDark);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 20px;
  font-weight: var(--fontWeightBold);
  line-height: 24px;
  margin-bottom: 12px;
  padding: 6px;
  text-align: left;
}
.nonprofit:focus {
  outline: none;
}
.nonprofit:hover {
  border-color: var(--textDark);
  box-shadow: var(--boxShadowButton);
}

.addNonprofit {
  display: flex;
  flex-direction: row;
  max-width: 50%;
}

.suggestions {
  background: var(--white);
  box-shadow: var(--boxShadowContextMenu);
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: var(--zIndexContextMenu);
}
.suggestion {
  cursor: pointer;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.logo {
  border-radius: 50%;
  height: 32px;
  margin-right: 12px;
  width: 32px;
}
